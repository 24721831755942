import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'

import Layout from '../components/Layout'
import Footer from '../components/Footer'
import SEO from '../components/SEO'

const footerLinks = ['the-kawatea', 'pot-fishing', 'past']

const NotFoundPage = ({ location, data }) => (
  <Layout notInMenu>
    <SEO title="404" description="404" location={location} />
    <Fade delay={225} duration={2250}>
      <div className="relative">
        <Img
          className="min-h-104 h-full w-full"
          fluid={data.heroImage.childImageSharp.fluid}
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <Fade delay={450} duration={2250}>
            <div className="uppercase font-medium text-center px-10">
              <h1 className="text-7xl md:text-9xl leading-tight tracking-wide">
                404
              </h1>
              <h2 className="tracking-widest">
                This page does not seem to exist
              </h2>
            </div>
          </Fade>
        </div>
      </div>
    </Fade>
    <Footer links={footerLinks} />
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export const NotFoundPageQuery = graphql`
  query {
    heroImage: file(relativePath: { eq: "hero/404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default NotFoundPage
